// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	ownerId: "wom",
	apiUrl: 'https://womdev-oma.omnixsystem.com/v3/monitor/',

	dashboard_id: "2c319f60-dc52-11ea-aef9-5bad6cdccc6b",
	simoApiUrl: "https://pn1fccny75.execute-api.us-east-2.amazonaws.com/dev/v1",
	
	path_login:'user/auth',
	path_readByStatus:'order/all/shippinggroup/status/',
	path_readByOrderStatus:'order/all/status/',
	path_createOrder:'order',
	path_getOrder:'order/',
	path_schedule:'order/schedule/',
	path_getSG:'order/',
	path_source:'source',
	path_user:'user/',
	path_getItems:'',
    path_couriers:'courier',
    path_zones: 'regions',
	path_reports: 'report',
	path_autocomplete: 'item/all/query',
	path_regions:'regions',
	path_bulkStatus : "order/bulkStatus",
	listStatus: [
		{
			value: 'all',
			label: 'Todos',
			name: 'all'
		},
		{
			value: '',
			label: 'Esperando Aceptación de Tienda',
			name: 'AWAITING_STORE_CONFIRMATION'
		},
		{
			value: 'accept',
			label: 'Aceptado',
			name: 'PENDING_PACKAGE'
		},
		{
			value: 'packed',
			label: 'Por Despachar',
			name: 'AWAITING_DELIVERY'
		},
		{
			value: 'shipped',
			label: 'En Tránsito',
			name: 'IN_TRANSIT'
		},
		{
			value: 'delivered',
			label: 'Entregado',
			name: 'DELIVERED'
		},
		{
			value: 'ready_for_pickup',
			label: 'Lista para Retiro',
			name: 'READY_FOR_PICKUP'
		},
        /*{
			value: 'delivered_inc',
			label: 'Entrega Incompleta',
			name: 'DELIVERED_INCOMPLETE'
		},*/
		{
			value: 'courier_reject',
			label: 'En devolución por transportista',
			name: 'IN_RETURN_COURIER'
		},
		{
			value: 'customer_reject',
			label: 'En devolución por el cliente',
			name: 'IN_RETURN'
		},
		{
			value: 'delivered_return',
			label: 'Devuelto',
			name: 'RETURNED'
		},
		{
			value: 'reject',
			label: 'Rechazado',
			name: 'REJECTED'
		},
		{
			value: 'cancel',
			label: 'Cancelado',
			name: 'CANCELED'
		},
		{
			value: 'cancel_decrease',
			label: 'Cancelado por Merma',
			name: 'CANCELED_DECREASE'
		},
		{
			value: 'cancel_logistic',
			label: 'Cancelado por Logística',
			name: 'CANCELED_LOGISTIC'
		},
		{
			value: 'close',
			label: 'Retirado',
			name: 'CLOSED'
		}
	],
	statusSG: [
		{
			value: '',
			label: 'Esperando Aceptación de Tienda',
			name: 'AWAITING_STORE_CONFIRMATION'
		},
		{
			value: 'accept',
			label: 'Aceptado',
			name: 'PENDING_PACKAGE'
		},
		{
			value: 'packed',
			label: 'Por Despachar',
			name: 'AWAITING_DELIVERY'
		},
		{
			value: 'shipped',
			label: 'En Tránsito',
			name: 'IN_TRANSIT'
		},
		{
			value: 'delivered',
			label: 'Entregado',
			name: 'DELIVERED'
		},
		{
			value: 'ready_for_pickup',
			label: 'Lista para Retiro',
			name: 'READY_FOR_PICKUP'
		},
		/*{
			value: 'delivered_inc',
            label: 'Entrega Incompleta',
            name: 'DELIVERED_INCOMPLETE'
		},*/
		{
			value: 'courier_reject',
			label: 'En devolución por transportista',
			name: 'IN_RETURN_COURIER'
		},
		{
			value: 'customer_reject',
			label: 'En devolución por el cliente',
			name: 'IN_RETURN'
		},
		{
			value: 'delivered_return',
			label: 'Devuelto',
			name: 'RETURNED'
		},
		{
			value: 'reject',
			label: 'Rechazado',
			name: 'REJECTED'
		},
		{
			value: 'cancel_decrease',
			label: 'Cancelado por Merma',
			name: 'CANCELED_DECREASE'
		},
		{
			value: 'cancel_logistic',
			label: 'Cancelado por Logística',
			name: 'CANCELED_LOGISTIC'
		},
		{
			value: 'close',
			label: 'Retirado',
			name: 'CLOSED'
		}
	],
	actionsSG: [
		{
			value: 	'reject',
			label: 	'Rechazado',
			name: 	'REJECTED'
		},
		{
			value: 	'accept',
			label: 	'Aceptado',
			name: 	'PENDING_PACKAGE'
		},
		{
			value: 	'packed',
			label: 	'Por Despachar',
			name: 	'AWAITING_DELIVERY'
		},
		{
			value: 	'shipped',
			label: 	'En Tránsito',
			name: 	'IN_TRANSIT'
		},
		/*{
			value: 	'delivered_inc',
			label: 	'Entrega Incompleta',
			name: 	'READY_FOR_PICKUP | DELIVERED | AWAITING_DELIVERY'
		},*/
		{
			value: 	'courier_reject',
			label: 	'Rechazado por courier',
			name: 	'IN_RETURN_COURIER'
		},
		{
			value: 	'customer_reject',
			label: 	'Rechazado por cliente',
			name: 	'IN_RETURN_CUSTOMER'
		},
		{
			value: 	'delivered',
			label: 	'Entregado',
			name: 	'READY_FOR_PICKUP | DELIVERED | AWAITING_DELIVERY'
		},
		{
			value: 	'picked_up',
			label: 	'Retirado',
			name: 	'CLOSED'
		},
		{
			value: 	'cancel',
			label: 	'Retiro Cancelado',
			name: 	'CANCEL'
		},
		{
			value: 	'cancel_decrease',
			label: 	'Cancelado por Merma',
			name: 	'CANCEL_DECREASE'
		},
		{
			value: 	'cancel_logistic',
			label: 	'Cancelado por Logística',
			name: 	'CANCEL_LOGISTIC'
		}
	],
	channels: [
		{ value: 'televentas', viewValue: 'TELEVENTAS' },
		{ value: 'retail', viewValue: 'RETAIL' },
		{ value: 'masivo', viewValue: 'MASIVO' },
		{ value: 'postventa', viewValue: 'POSTVENTA' },
		{ value: 'business_bin', viewValue: 'BUSINESS BIN' },
		{ value: 'ott', viewValue: 'OTT' }
	],
	orderStatus: [
		{
			value: 'reserved',
			label: 'Reservado',
			name: 'RESERVED'
		},
		{
			value: 'pending_preparation',
			label: 'Preparación Pendiente',
			name: 'PENDING_PREPARATION'
		},
		{
			value: 'in_transit',
			label: 'En Tránsito',
			name: 'IN_TRANSIT'
		},
		{
			value: 'ready_for_pickup',
			label: 'Listo para recoger',
			name: 'READY_FOR_PICKUP'
		},
		{
			value: 'delivered',
			label: 'Entregado',
			name: 'DELIVERED'
		},
		{
			value: 'closed',
			label: 'Cerrado',
			name: 'CLOSED'
		},
		{
			value: 'canceled',
			label: 'Cancelado',
			name: 'CANCELED'
		}
	],
	listOrderStatus: [
		{
			value: 'all',
			label: 'Todos',
			name: 'all'
		},
		{
			value: 'reserved',
			label: 'Reservado',
			name: 'RESERVED'
		},
		{
			value: 'pending_preparation',
			label: 'Preparación Pendiente',
			name: 'PENDING_PREPARATION'
		},
		{
			value: 'in_transit',
			label: 'En Tránsito',
			name: 'IN_TRANSIT'
		},
		{
			value: 'ready_for_pickup',
			label: 'Listo para recoger',
			name: 'READY_FOR_PICKUP'
		},
		{
			value: 'delivered',
			label: 'Entregado',
			name: 'DELIVERED'
		},
		{
			value: 'closed',
			label: 'Cerrado',
			name: 'CLOSED'
		},
		{
			value: 'canceled',
			label: 'Cancelado',
			name: 'CANCELED'
		}
	],
	actionsOrder: [
		{
			value: 	'confirm',
			label: 	'Aceptar',
			name: 	'confirm'
		},
		{
			value: 	'close',
			label: 	'Cerrar',
			name: 	'close'
		},
		{
			value: 	'cancel',
			label: 	'Cancelar',
			name: 	'AWAITING_DELIVERY'
		}
	],
};
