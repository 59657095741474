import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '@models/user';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        if(this.currentUserSubject)
            return this.currentUserSubject.value;
        return null
    }

    login($username, $password) {
        return this.http.post<any>(
            environment.apiUrl+environment.path_login,
            {
                "key": $username,
                "secret": $password
            }).pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                this.currentUserSubject.next(user.message);
                return user;
            })
        );
    }

    logout() {
        // remove user from local storage and set current user to null
        //this.currentUserSubject.next({} as User);
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        localStorage.removeItem('simoToken');
    }

    sendEmail(data) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        let options = { headers: headers };

        return this.http.post<any>(
            environment.apiUrl+environment.path_user+'me/recover', data, options
        ).pipe(map(message => {
            return message;
        }));
    }

    validateToken(data) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        let options = { headers: headers };

        return this.http.post<any>(
            environment.apiUrl+environment.path_user+'me/recover/validate', data, options
        ).pipe(map(message => {
            return message;
        }));
    }

    changePassword(data) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        let options = { headers: headers };

        return this.http.post<any>(
            environment.apiUrl+environment.path_user+'me/recover/change', data, options
        ).pipe(map(message => {
            return message;
        }));
    }
}
